import Link from 'next/link';

import * as styles from './index.module.scss';

const PageNotFound = () => (
  <div className={styles['page-not-found-container']}>
    <div className={`${styles['container']} container`}>
      <h1>Oops!</h1>
      <p>Sorry, page not found.</p>
      <Link href="/" className="button mini secondary">
        Back to home
      </Link>
    </div>
  </div>
);

export default PageNotFound;
