import React from 'react';

import Head from 'next/head';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import PageNotFound from '@/components/PageNotFound';

const Home = () => {
  return (
    <div className="page-wrapper">
      <Head>
        <meta name="description" content="CallAPro - Find a Home Service Professional Near You" />
        <title>Find Home Service Pros Near You</title>
      </Head>
      <Header />
      <PageNotFound />
      <Footer />
    </div>
  );
};

export default Home;
